import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "./message/message.component";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  constructor(private http: HttpClient, public snackBar: MatSnackBar) {}

  /**
   * Assign params with key value pare
   * @param params: any
   */
  private getHttpParams(params) {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        httpParams = httpParams.set(key, element);
      }
    }
    return httpParams;
  }

  getOrgInsuranceList(params) {
    const httpParams = this.getHttpParams(params);
    return this.http.get(`/members/`, { params: httpParams });
  }

  addOrgInsuranceList(formValues: any, id: string) {
    return this.http.post(`/members/${id}/create`, formValues);
  }

  editOrgInsuranceList(formValues: any, id: string) {
    return this.http.put(`/members/${id}`, formValues);
  }

  deleteOrgInsuranceList(id: string) {
    return this.http.delete(`/members/${id}`);
  }

  activateMember(id: string) {
    return this.http.patch(`/members/${id}/activate`, {});
  }

  deactivateMember(id: string) {
    return this.http.patch(`/members/${id}/deactivate`, {});
  }

  downloadReport(id: string) {
    return this.http.get(`/members/report/${id}`, { responseType: "text" });
  }

  downloadSampleReport() {
    return this.http.get(`/members/sample`, { responseType: "text" });
  }

  uploadExcel(data, replace?) {
    return this.http.post(`/members/upload`, data);
  }

  showMessage(message: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }
}
