<app-home-header></app-home-header>

<div class="container main">
  <div class="row primerBloque">
    <div class="col-9" style="padding-left: 20px;">
      <h1 class="title-dashboard">
        Dashboard
      </h1>
      <p class="sub-title-dashboard" style="margin-top: -15px;">
        {{ "Home" | translate }} /
        <span class="sub-title-dashboard-2">Dashboard</span>
      </p>
    </div>
    <div class="col-3 d-flex justify-content-end gap-4">
      <button
        *ngIf="providersList.length > 1"
        [disabled]="isLoading || isLoadingReport"
        mat-button
        [matMenuTriggerFor]="menu1"
        style="
          background-color: #fff;
          border: 1px solid #d4d4d4;
          height: 48px;
          width: 190px;
          color: black;
          font-family: 'Nunito', sans-serif;
          min-width: 273px;
          margin-right: 20px;
          text-align: left;
        "
      >
        {{ providerSelected.businessName }}
        <mat-icon style="float: right; margin-top: 8px;"
          >keyboard_arrow_down</mat-icon
        >
      </button>

      <mat-menu #menu1="matMenu">
        <button
          *ngFor="let provider of providersList"
          mat-menu-item
          style="
            width: 207px;
            font-size: 15px;
            font-family: 'Nunito', sans-serif;
          "
          (click)="changeProvider(provider)"
        >
          {{ provider.businessName }}
        </button>
      </mat-menu>

      <button
        [disabled]="isLoading || isLoadingReport"
        mat-button
        [matMenuTriggerFor]="menu2"
        style="
          background-color: #244791;
          height: 48px;
          width: 190px;
          color: white;
          font-family: 'Nunito', sans-serif;
          min-width: 190px;
        "
      >
        <mat-icon>date_range</mat-icon> {{ rangeSelected.label }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <mat-menu #menu2="matMenu">
        <button
          *ngFor="let range of dateRanges"
          mat-menu-item
          style="
            width: 207px;
            font-size: 15px;
            font-family: 'Nunito', sans-serif;
          "
          (click)="changeRange(range.value)"
        >
          {{ range.label }}
        </button>
      </mat-menu>

      <button
        (click)="saveAsPdf()"
        [disabled]="isLoadingReport"
        style="
          color: #2751a5;
          font-weight: 600;
          border: 2.4px solid #2751a5;
          border-radius: 6px;
          height: 48px;
          width: 190px;
          font-family: 'Nunito', sans-serif;
          min-width: 190px;
        "
      >
        Descargar informe
      </button>
    </div>
  </div>

  <div class="row segundoBloque">
    <div class="col-6 card-responsive">
      <mat-card class="card-container chart-container">
        <div class="row">
          <div class="col-10">
            <mat-card-title
              style="
                font-size: 20px;
                font-family: 'Nunito', sans-serif;
                font-weight: bold;
              "
            >
              Usuarios registrados
              <mat-icon
                style="position: absolute; margin-left: 5px; color: #244791;"
                matTooltip="Personas que han completado el proceso de registro en HolaDOC."
                matTooltipClass="custom-tooltips"
                matTooltipPosition="after"
                >info_outline</mat-icon
              >
            </mat-card-title>
            <mat-card-title *ngIf="!isLoading" class="card-subtitle">
              <span
                style="
                  font-size: 24px;
                  font-weight: 700;
                  font-family: 'Nunito', sans-serif;
                "
              >
                {{ isData ? totalRegistered : "0" }}
              </span>
              <span
                *ngIf="members?.trend > 0 && isData"
                style="
                  font-size: 14px;
                  top: 45px;
                  margin-left: 10px;
                  color: #23bc60;
                "
              >
                <div style="display: flex; align-items: center; gap: 10px;">
                  <img
                    style="width: 12px;"
                    src="../../assets/images/flecha-verde.png"
                    alt=""
                  />
                  <span
                    style="font-size: 14px; font-weight: 400; margin-top: 1%;"
                  >
                    {{ isInteger(members.trend) }}%
                  </span>
                </div>
              </span>
              <span
                *ngIf="members?.trend < 0 && isData"
                style="
                  font-size: 14px;
                  top: 40px;
                  margin-left: 10px;
                  color: #d22f37;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-roja.png"
                  alt=""
                />
                {{ isInteger(members.trend) }}%
              </span>
              <span
                *ngIf="members?.trend == 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 40px;
                  margin-left: 10px;
                  color: rgba(0, 0, 0, 0.4);
                "
              >
                -- sin var.
              </span>
            </mat-card-title>

            <mat-card-title *ngIf="isLoading" class="card-process-bar">
              <mat-progress-bar color="warn" mode="indeterminate">
              </mat-progress-bar
            ></mat-card-title>

            <mat-card-subtitle *ngIf="!isLoading" class="card-info">
              Total miembros: <b> {{ totalMembers }} </b>
            </mat-card-subtitle>

            <mat-card-subtitle *ngIf="isLoading" class="card-process-bar-info">
              <mat-progress-bar
                *ngIf="isLoading"
                color="warn"
                mode="indeterminate"
              ></mat-progress-bar>
            </mat-card-subtitle>
          </div>
          <div class="col-2">
            <button
              disabled
              mat-fab
              style="
                color: #244791;
                background-color: #d8e4fc;
                width: 64px;
                height: 64px;
              "
            >
              <img
                style="width: 95%; margin-top: -10%;"
                src="../../assets/images/Icon.svg"
                alt=""
              />
            </button>
          </div>
        </div>
        <mat-divider style="margin-top: 15px;"></mat-divider>

        <mat-card-content>
          <mat-spinner
            *ngIf="isLoading"
            style="margin-top: 20%; margin-left: 45%;"
            diameter="60"
          ></mat-spinner>

          <div *ngIf="!isLoading && members?.dates?.length <= 0">
            <p class="no-data-graphic">
              El gráfico aún no se encuentra disponible
            </p>
            <img
              style="width: 100%; height: 100%; margin-top: 20px;"
              src="assets/images/no-data-graphic.png"
            />
          </div>

          <div #canvasUser id="canvas2" class="line-graphic"></div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-6 card-responsive">
      <mat-card class="card-container chart-container">
        <div class="row">
          <div class="col-10">
            <mat-card-title
              style="
                font-size: 20px;
                font-family: 'Nunito', sans-serif;
                font-weight: bold;
              "
            >
              Consultas atendidas
              <mat-icon
                style="position: absolute; margin-left: 5px; color: #244791;"
                matTooltip="Número de consultas atendidas por el equipo médico de HolaDOC en el período de tiempo seleccionado."
                matTooltipClass="custom-tooltips"
                matTooltipPosition="after"
              >
                info_outline
              </mat-icon>
            </mat-card-title>
            <mat-card-title *ngIf="!isLoading" class="card-subtitle">
              <span
                style="
                  font-size: 24px;
                  font-weight: 700;
                  font-family: 'Nunito', sans-serif;
                "
              >
                {{ isData ? totalConsults : "0" }}
              </span>
              <span
                *ngIf="dashboardData?.trendConsultation > 0 && isData"
                style="
                  font-size: 14px;
                  top: 44px;
                  margin-left: 10px;
                  color: #23bc60;
                "
              >
                <div
                  class="testeo"
                  style="display: flex; align-items: center; gap: 10px;"
                >
                  <img
                    style="width: 12px;"
                    src="../../assets/images/flecha-verde.png"
                    alt=""
                  />
                  <span
                    style="font-size: 14px; font-weight: 400; margin-top: 1%;"
                  >
                    {{ isInteger(dashboardData.trendConsultation) }}%
                  </span>
                </div>
              </span>
              <span
                *ngIf="dashboardData?.trendConsultation < 0 && isData"
                style="
                  font-size: 14px;
                  top: 40px;
                  margin-left: 10px;
                  color: #d22f37;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-roja.png"
                  alt=""
                />
                {{ isInteger(dashboardData.trendConsultation) }}%
              </span>
              <span
                *ngIf="dashboardData?.trendConsultation == 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 40px;
                  margin-left: 10px;
                  color: rgba(0, 0, 0, 0.4);
                "
              >
                -- sin var.
              </span>
            </mat-card-title>
            <mat-card-title *ngIf="isLoading" class="card-process-bar">
              <mat-progress-bar color="warn" mode="indeterminate">
              </mat-progress-bar
            ></mat-card-title>

            <mat-card-subtitle *ngIf="!isLoading" class="card-info">
              Primarias: <b> {{ isData ? primaries : "0" }} </b> - Seguimiento:
              <b> {{ isData ? successive : "0" }} </b>
            </mat-card-subtitle>

            <mat-card-subtitle *ngIf="isLoading" class="card-process-bar-info">
              <mat-progress-bar
                *ngIf="isLoading"
                color="warn"
                mode="indeterminate"
              ></mat-progress-bar>
            </mat-card-subtitle>
          </div>
          <div class="col-2">
            <button
              disabled
              mat-fab
              style="
                color: #244791;
                background-color: #d8e4fc;
                width: 64px;
                height: 64px;
              "
            >
              <img
                style="width: 90%; margin-top: -10%;"
                src="../../assets/images/maleta.svg"
                alt=""
              />
            </button>
          </div>
        </div>

        <mat-divider style="margin-top: 15px;"></mat-divider>
        <mat-card-content>
          <mat-spinner
            *ngIf="isLoading"
            style="margin-top: 20%; margin-left: 45%;"
            diameter="60"
          ></mat-spinner>
          <div *ngIf="!isLoading && dashboardData?.dates?.length <= 0">
            <p class="no-data-graphic">
              El gráfico aún no se encuentra disponible
            </p>
            <img
              style="width: 100%; height: 100%; margin-top: 20px;"
              src="assets/images/no-data-graphic.png"
            />
          </div>

          <div #canvasConsult id="canvas3" class="line-graphic"></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row tercerBloque" style="margin-top: 15px;">
    <div class="col-4 card-min-container-responsive" style="margin-top: 5%;">
      <div class="first-block">
        <div class="tasaValoracion" style="display: flex; gap: 34px;">
          <!-- TASA DE USO -->
          <mat-card
            class="card-min-container col-5 chart-container"
            data-chartName="tasaUso"
          >
            <div class="row">
              <div class="col-12 card-min-responsive">
                <mat-card-title class="card-min-title">
                  Tasa de uso
                  <mat-icon
                    style="
                      position: absolute;
                      margin-left: 5px;
                      color: #244791;
                    "
                    matTooltip="Porcentaje de uso del servicio de telemedicina por parte de los miembros de su organización. Este indicador excluye consultas de seguimiento y consultas posteriores por nuevas patologías."
                    matTooltipClass="custom-tooltips"
                    matTooltipPosition="after"
                    >info_outline
                  </mat-icon>
                </mat-card-title>

                <mat-card-title *ngIf="!isLoading" class="card-min-subtitle">
                  <div class="content-card">
                    <span class="value">
                      {{
                        dashboardData?.rateConsultation > 0
                          ? isInteger(dashboardData.rateConsultation) + "%"
                          : "0%"
                      }}
                    </span>

                    <span
                      *ngIf="dashboardData?.trendConsultation == 0 && !isData"
                      style="
                        font-size: 14px;
                        font-family: 'open sans', sans-serif;
                        top: 57px;
                        margin-left: 10px;
                        color: #23bc60;
                      "
                    >
                      <img
                        style="width: 10px;"
                        src="../../assets/images/flecha-verde.png"
                        alt=""
                      />
                      <span>
                        {{ isInteger(dashboardData.trendConsultation) }}%
                      </span>
                    </span>
                  </div>
                  <span
                    *ngIf="dashboardData?.trendConsultation < 0 && isData"
                    style="
                      font-size: 14px;
                      top: 50px;
                      margin-left: 10px;
                      color: #d22f37;
                    "
                  >
                    <img
                      style="width: 10px;"
                      src="../../assets/images/flecha-roja.png"
                      alt=""
                    />
                    {{ isInteger(dashboardData.trendConsultation) }}%
                  </span>
                  <span
                    *ngIf="dashboardData?.trendConsultation == 0 && isData"
                    style="
                      font-size: 14px;
                      top: 50px;
                      margin-left: 10px;
                      color: rgba(0, 0, 0, 0.4);
                    "
                  >
                    -- sin var.
                  </span>
                </mat-card-title>
                <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
                  <mat-progress-bar color="warn" mode="indeterminate">
                  </mat-progress-bar
                ></mat-card-title>
              </div>
              <div class="col-2" style="display: none;">
                <button
                  disabled
                  style="
                    color: #244791;
                    background-color: #d8e4fc;
                    width: 64px;
                    height: 64px;
                  "
                  mat-fab
                >
                  <img
                    style="width: 95%; margin-top: -10%;"
                    src="../../assets/images/coolicon.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </mat-card>

          <!-- VALORACION MEDIA -->
          <mat-card
            class="card-min-container col-6 chart-container"
            style="margin-bottom: 20px;"
            data-chartName="valoracionMedia"
          >
            <div class="row">
              <div class="col-12 card-min-responsive">
                <mat-card-title class="card-min-title"
                  >Valoración media
                  <mat-icon
                    style="
                      position: absolute;
                      margin-left: 5px;
                      color: #244791;
                    "
                    matTooltip="Valoración promedio del servicio de telemedicina HolaDOC, por parte de los miembros de su organización considerando el período de tiempo seleccionado."
                    matTooltipClass="custom-tooltips"
                    matTooltipPosition="after"
                  >
                    info_outline
                  </mat-icon>
                </mat-card-title>
                <mat-card-title
                  *ngIf="!isLoading && dashboardData?.rateRating > 0"
                  class="card-min-subtitle content-card"
                >
                  <span class="value">
                    {{ isInteger(dashboardData.rateRating) + "/5" }}
                  </span>
                  <span
                    *ngIf="dashboardData.trendRating > 0 && isData"
                    style="
                      font-size: 14px;
                      top: 50px;
                      margin-left: 10px;
                      color: #23bc60;
                    "
                  >
                    <img
                      style="width: 10px;"
                      src="../../assets/images/flecha-verde.png"
                      alt=""
                    />
                    {{ isInteger(dashboardData.trendRating) }}%
                  </span>
                  <span
                    *ngIf="isData && dashboardData.trendRating < 0"
                    style="
                      font-size: 14px;
                      top: 50px;
                      margin-left: 10px;
                      color: #d22f37;
                    "
                  >
                    <img
                      style="width: 10px;"
                      src="../../assets/images/flecha-roja.png"
                      alt=""
                    />
                    {{ isInteger(dashboardData.trendRating) }}%
                  </span>
                  <span
                    *ngIf="isData && dashboardData.trendRating == 0"
                    style="
                      font-size: 14px;
                      top: 50px;
                      margin-left: 10px;
                      color: rgba(0, 0, 0, 0.4);
                    "
                  >
                    -- sin var.
                  </span>
                </mat-card-title>

                <mat-card-title
                  *ngIf="!isLoading && !dashboardData?.rateRating"
                  class="card-min-subtitle valoracionMediaTitle"
                >
                  <div
                    class="valoracionMedia d-flex align-items-center"
                    style="gap: 10px;"
                  >
                    <span
                      style="
                        font-size: 24px;
                        font-family: Nunito;
                        font-weight: 700;
                        line-height: 32px;
                        letter-spacing: 0em;
                      "
                    >
                      0/5
                    </span>

                    <span
                      style="
                        font-size: 14px;
                        top: 50px;
                        color: rgba(0, 0, 0, 0.4);
                        font-family: Nunito;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                      "
                    >
                      -- sin var.
                    </span>
                  </div>
                </mat-card-title>
                <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
                  <mat-progress-bar color="warn" mode="indeterminate">
                  </mat-progress-bar
                ></mat-card-title>
              </div>
              <div class="col-2" style="display: none;">
                <button
                  disabled
                  mat-fab
                  style="
                    color: #244791;
                    background-color: #d8e4fc;
                    width: 64px;
                    height: 64px;
                  "
                >
                  <mat-icon style="transform: scale(1.24); font-weight: bold;"
                    >grade</mat-icon
                  >
                </button>
              </div>
            </div>
          </mat-card>
        </div>

        <!-- TASA DE REGISTRO ACUMULADA -->
        <mat-card
          class="card-min-container chart-container"
          data-chartName="tasaRegistroAcumulada"
        >
          <div class="row">
            <div class="col-9 col-sm-10 card-min-responsive">
              <mat-card-title class="card-min-title"
                >Tasa de registro acumulada
                <mat-icon
                  style="position: absolute; margin-left: 5px; color: #244791;"
                  matTooltip="Porcentaje usuarios registrados en HolaDOC, en relación a los miembros de su organización."
                  matTooltipClass="custom-tooltips"
                  matTooltipPosition="after"
                  >info_outline</mat-icon
                ></mat-card-title
              >
              <mat-card-title
                *ngIf="!isLoading"
                class="card-min-subtitle content-card"
              >
                <span class="value">
                  {{
                    members?.accumulatedRegistered > 0
                      ? isInteger(members.accumulatedRegistered) + "%"
                      : "0%"
                  }}
                </span>
              </mat-card-title>
              <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
                <mat-progress-bar color="warn" mode="indeterminate">
                </mat-progress-bar
              ></mat-card-title>
            </div>
            <div class="col-2" style="display: none;">
              <button
                disabled
                mat-fab
                style="
                  color: #244791;
                  background-color: #d8e4fc;
                  width: 64px;
                  height: 64px;
                "
              >
                <mat-icon style="transform: scale(1.2); font-weight: bold;">
                  data_usage</mat-icon
                >
              </button>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="second-block">
        <!-- USUARIOS CON EL USO DEL SERVICIO -->
        <mat-card
          class="card-min-container chart-container"
          data-chartName="usuariosUsoServicio"
        >
          <div class="row">
            <div class="col-9 col-sm-10 card-min-responsive">
              <mat-card-title class="card-min-title">
                Usuarios con el uso del servicio
                <mat-icon
                  style="position: absolute; margin-left: 5px; color: #244791;"
                  matTooltip="Indica la cantidad de miembros de su organización que han usado el servicio al menos 1 vez."
                  matTooltipClass="custom-tooltips"
                  matTooltipPosition="after"
                  >info_outline</mat-icon
                ></mat-card-title
              >
              <mat-card-title
                *ngIf="!isLoading"
                class="card-min-subtitle content-card"
              >
                <span class="value">
                  {{
                    dashboardData?.usersWithServiceUsage > 0
                      ? thousandSeparator(dashboardData.usersWithServiceUsage)
                      : "0"
                  }}
                </span>
              </mat-card-title>
              <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
                <mat-progress-bar color="warn" mode="indeterminate">
                </mat-progress-bar
              ></mat-card-title>
            </div>
            <div class="col-2" style="display: none;">
              <button
                disabled
                mat-fab
                style="
                  color: #244791;
                  background-color: #d8e4fc;
                  width: 64px;
                  height: 64px;
                "
              >
                <mat-icon style="transform: scale(1.2); font-weight: bold;"
                  >data_usage</mat-icon
                >
              </button>
            </div>
          </div>
        </mat-card>

        <!-- TASA DE RECURRENCIA -->
        <mat-card
          class="card-min-container chart-container"
          data-chartName="tasaRecurrencia"
        >
          <div class="row">
            <div class="col-9 col-sm-10 card-min-responsive">
              <mat-card-title class="card-min-title"
                >Tasa de recurrencia
                <mat-icon
                  style="position: absolute; margin-left: 5px; color: #244791;"
                  matTooltip="Porcentaje de miembros que han usado el servicio de telemedicina más de 2 veces."
                  matTooltipClass="custom-tooltips"
                  matTooltipPosition="after"
                >
                  info_outline
                </mat-icon>
              </mat-card-title>
              <mat-card-title
                *ngIf="!isLoading"
                class="card-min-subtitle content-card"
              >
                <div class="value">
                  {{
                    dashboardData?.recurrenceRate > 0
                      ? isInteger(dashboardData.recurrenceRate) + "%"
                      : "0%"
                  }}
                </div>
              </mat-card-title>
              <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
                <mat-progress-bar color="warn" mode="indeterminate">
                </mat-progress-bar
              ></mat-card-title>
            </div>
            <div class="col-2" style="display: none;">
              <button
                disabled
                mat-fab
                style="
                  color: #244791;
                  background-color: #d8e4fc;
                  width: 64px;
                  height: 64px;
                "
              >
                <mat-icon style="transform: scale(1.2); font-weight: bold;">
                  data_usage
                </mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <!-- NAVEGACION -->
    <div class="col-8 card-responsive">
      <mat-tab-group
        animationDuration="0"
        class="remove-border-bottom"
        (selectedTabChange)="changeTab($event)"
      >
        <mat-tab label="{{ chartTabs.motivosConsulta.title }}">
          <mat-card class="card-container" style="height: 390px;">
            <div class="chart-content">
              <mat-card-title
                style="
                  font-size: 20px;
                  font-family: 'Nunito', sans-serif;
                  font-weight: bold;
                "
              >
                {{ chartTabs.motivosConsulta.title }}
              </mat-card-title>
              <mat-divider></mat-divider>
              <mat-card-content>
                <mat-spinner
                  *ngIf="isLoading"
                  style="margin-top: 20%; margin-left: 45%;"
                  diameter="60"
                ></mat-spinner>
                <div
                  *ngIf="
                    !isLoading && dashboardData?.topChiefComplaint?.length === 0
                  "
                >
                  <p class="no-data-graphic no-data-graphic--tab">
                    El gráfico aún no se encuentra disponible
                  </p>
                </div>
                <div #canvasPies id="canvasPies" class="pie-graphic"></div>
              </mat-card-content>
            </div>
          </mat-card>
        </mat-tab>

        <mat-tab
          label="{{ chartTabs.consultasPorGenero.title }}"
          class="mat-tab-body-origin"
        >
          <mat-card class="card-container" style="height: 390px;">
            <div class="chart-content">
              <mat-card-title
                style="
                  font-size: 20px;
                  font-family: 'Nunito', sans-serif;
                  font-weight: bold;
                "
              >
                {{ chartTabs.consultasPorGenero.title }}
              </mat-card-title>
              <mat-divider></mat-divider>
              <mat-card-content>
                <mat-spinner
                  *ngIf="isLoading"
                  style="margin-top: 20%; margin-left: 45%;"
                  diameter="60"
                ></mat-spinner>
                <div *ngIf="!isLoading && gendersLength === 0">
                  <p class="no-data-graphic no-data-graphic--tab">
                    El gráfico aún no se encuentra disponible
                  </p>
                </div>
                <div
                  #canvasPieGender
                  id="canvasPieGender"
                  class="pie-graphic"
                ></div>
              </mat-card-content>
            </div>
          </mat-card>
        </mat-tab>

        <mat-tab
          label="{{ chartTabs.consultasPorEdad.title }}"
          class="mat-tab-body-origin"
        >
          <mat-card class="card-container" style="height: 390px;">
            <div class="chart-content">
              <mat-card-title
                style="
                  font-size: 20px;
                  font-family: 'Nunito', sans-serif;
                  font-weight: bold;
                "
              >
                {{ chartTabs.consultasPorEdad.title }}
              </mat-card-title>

              <mat-divider></mat-divider>

              <mat-card-content>
                <mat-spinner
                  *ngIf="isLoading"
                  style="margin-top: 20%; margin-left: 45%;"
                  diameter="60"
                ></mat-spinner>
                <div
                  *ngIf="
                    !isLoading && dashboardData?.topChiefComplaint?.length === 0
                  "
                >
                  <p
                    class="no-data-graphic no-data-graphic--tab bar-graphic-message"
                  >
                    El gráfico aún no se encuentra disponible
                  </p>
                </div>
                <div
                  #canvasBarPerAge
                  id="canvasBarPerAge"
                  class="bar-graphic w-100"
                ></div>
              </mat-card-content>
            </div>
          </mat-card>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<!-- BLOQUE DE CONTENIDO PARA EL PDF  -->
<div
  class="container-fluid row justify-content-center gap-2 card-responsive"
  id="hidden-container"
  style="position: fixed; opacity: 0;"
>
  <div style="width: 20cm;">
    <mat-card
      class="card-container chart-container-pdf mb-1"
      data-chartName="consultasAtendidas"
    >
      <div class="row">
        <div class="col-10">
          <mat-card-title
            style="
              font-size: 20px;
              font-family: 'Nunito', sans-serif;
              font-weight: bold;
            "
          >
            Consultas atendidas
            <mat-icon
              style="position: absolute; margin-left: 5px; color: #244791;"
              matTooltip="Número de consultas atendidas por el equipo médico de HolaDOC en el período de tiempo seleccionado."
              matTooltipClass="custom-tooltips"
              matTooltipPosition="after"
            >
              info_outline
            </mat-icon>
          </mat-card-title>
          <mat-card-title *ngIf="!isLoading" class="card-subtitle">
            <span
              style="
                font-size: 24px;
                font-weight: 700;
                font-family: 'Nunito', sans-serif;
              "
            >
              {{ isData ? totalConsults : "0" }}
            </span>
            <span
              *ngIf="dashboardData?.trendConsultation > 0 && isData"
              style="
                font-size: 14px;
                top: 44px;
                margin-left: 10px;
                color: #23bc60;
              "
            >
              <div
                class="testeo"
                style="display: flex; align-items: center; gap: 10px;"
              >
                <img
                  style="width: 12px;"
                  src="../../assets/images/flecha-verde.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; font-weight: 400; margin-top: 1%;"
                >
                  {{ isInteger(dashboardData.trendConsultation) }}%
                </span>
              </div>
            </span>
            <span
              *ngIf="dashboardData?.trendConsultation < 0 && isData"
              style="
                font-size: 14px;
                top: 40px;
                margin-left: 10px;
                color: #d22f37;
              "
            >
              <img
                style="width: 10px;"
                src="../../assets/images/flecha-roja.png"
                alt=""
              />
              {{ isInteger(dashboardData.trendConsultation) }}%
            </span>
            <span
              *ngIf="dashboardData?.trendConsultation == 0 && isData"
              style="
                font-size: 14px;
                top: 40px;
                margin-left: 10px;
                color: rgba(0, 0, 0, 0.4);
              "
            >
              -- sin var.
            </span>
          </mat-card-title>
          <mat-card-title *ngIf="isLoading" class="card-process-bar">
            <mat-progress-bar color="warn" mode="indeterminate">
            </mat-progress-bar
          ></mat-card-title>
          <mat-card-subtitle *ngIf="!isLoading" class="card-info"
            >Primarias: <b> {{ isData ? primaries : "0" }} </b> - Seguimiento:
            <b> {{ isData ? successive : "0" }} </b>
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="isLoading" class="card-process-bar-info">
            <mat-progress-bar
              *ngIf="isLoading"
              color="warn"
              mode="indeterminate"
            ></mat-progress-bar>
          </mat-card-subtitle>
        </div>
        <div class="col-2">
          <button
            disabled
            mat-fab
            style="
              color: #244791;
              background-color: #d8e4fc;
              width: 64px;
              height: 64px;
            "
          >
            <img
              style="width: 90%; margin-top: -10%;"
              src="../../assets/images/maleta.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      <mat-divider style="margin-top: 15px;"></mat-divider>
      <mat-card-content>
        <mat-spinner
          *ngIf="isLoading"
          style="margin-top: 20%; margin-left: 45%;"
          diameter="60"
        ></mat-spinner>
        <div *ngIf="!isLoading && dashboardData?.dates?.length <= 0">
          <p class="no-data-graphic-pdf compound">
            El gráfico aún no se encuentra disponible
          </p>
        </div>

        <div
          #consultasAtendidasPDF
          id="consultasAtendidasPDF"
          class="line-graphic-PDF"
        ></div>
      </mat-card-content>
    </mat-card>

    <!-- DISTRIBUCION DE CONSULTAS POR GENERO -->
    <mat-card
      class="card-container chart-container-pdf mb-1"
      data-chartName="usuariosRegistrados"
    >
      <div class="row">
        <div class="col-10">
          <mat-card-title
            style="
              font-size: 20px;
              font-family: 'Nunito', sans-serif;
              font-weight: bold;
            "
          >
            Usuarios registrados
            <mat-icon
              style="position: absolute; margin-left: 5px; color: #244791;"
              matTooltip="Personas que han instalado la app de HolaDOC y completado el proceso de registro en ella, al introducir y validar sus datos personales y creando sus credenciales de acceso."
              matTooltipClass="custom-tooltips"
              matTooltipPosition="after"
              >info_outline
            </mat-icon>
          </mat-card-title>
          <mat-card-title *ngIf="!isLoading" class="card-subtitle">
            <span
              style="
                font-size: 24px;
                font-weight: 700;
                font-family: 'Nunito', sans-serif;
              "
            >
              {{ isData ? totalRegistered : "0" }}
            </span>
            <span
              *ngIf="members?.trend > 0 && isData"
              style="
                font-size: 14px;
                top: 45px;
                margin-left: 10px;
                color: #23bc60;
              "
            >
              <div
                class="testeo"
                style="display: flex; align-items: center; gap: 10px;"
              >
                <img
                  style="width: 12px;"
                  src="../../assets/images/flecha-verde.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; font-weight: 400; margin-top: 1%;"
                >
                  {{ isInteger(members.trend) }}%
                </span>
              </div>
            </span>
            <span
              *ngIf="members?.trend < 0 && isData"
              style="
                font-size: 14px;
                top: 40px;
                margin-left: 10px;
                color: #d22f37;
              "
            >
              <img
                style="width: 10px;"
                src="../../assets/images/flecha-roja.png"
                alt=""
              />
              {{ isInteger(members.trend) }}%
            </span>
            <span
              *ngIf="members?.trend == 0 && isData"
              style="
                font-size: 14px;
                top: 40px;
                margin-left: 10px;
                color: rgba(0, 0, 0, 0.4);
              "
            >
              -- sin var.
            </span>
          </mat-card-title>

          <mat-card-title *ngIf="isLoading" class="card-process-bar">
            <mat-progress-bar color="warn" mode="indeterminate">
            </mat-progress-bar
          ></mat-card-title>

          <mat-card-subtitle *ngIf="!isLoading" class="card-info"
            >Total miembros: <b> {{ totalMembers }} </b>
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="isLoading" class="card-process-bar-info">
            <mat-progress-bar
              *ngIf="isLoading"
              color="warn"
              mode="indeterminate"
            ></mat-progress-bar>
          </mat-card-subtitle>
        </div>
        <div class="col-2">
          <button
            disabled
            mat-fab
            style="
              color: #244791;
              background-color: #d8e4fc;
              width: 64px;
              height: 64px;
            "
          >
            <img
              style="width: 95%; margin-top: -10%;"
              src="../../assets/images/Icon.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      <mat-divider style="margin-top: 15px;"></mat-divider>

      <mat-card-content>
        <mat-spinner
          *ngIf="isLoading"
          style="margin-top: 20%; margin-left: 45%;"
          diameter="60"
        ></mat-spinner>

        <div *ngIf="!isLoading && members?.dates?.length <= 0">
          <p class="no-data-graphic-pdf compound">
            El gráfico aún no se encuentra disponible
          </p>
        </div>

        <div
          #usuariosRegistradosPDF
          id="usuariosRegistradosPDF"
          class="line-graphic-PDF"
        ></div>
      </mat-card-content>
    </mat-card>

    <!-- TOP 10 MOTIVOS DE CONSULTA -->
    <mat-card
      style="background: #f3f3f3;"
      class="card-container chart-container-pdf mb-1"
      data-chartName="topMotivosConsulta"
    >
      <div class="chart-content">
        <mat-card-title
          style="
            font-size: 20px;
            font-family: 'Nunito', sans-serif;
            font-weight: bold;
          "
        >
          {{ chartTabs.motivosConsulta.title }}
          <mat-icon
            style="position: absolute; margin-left: 5px; color: #244791;"
            matTooltip="Principales motivos de consulta realizados por los usuarios de su organización en el período de tiempo seleccionado."
            matTooltipClass="custom-tooltips"
            matTooltipPosition="after"
            >info_outline</mat-icon
          ></mat-card-title
        >
        <mat-divider></mat-divider>
        <mat-card-content>
          <mat-spinner
            *ngIf="isLoading"
            style="margin-top: 20%; margin-left: 45%;"
            diameter="60"
          ></mat-spinner>
          <div
            *ngIf="!isLoading && dashboardData?.topChiefComplaint?.length === 0"
          >
            <p class="no-data-graphic-pdf simple">
              El gráfico aún no se encuentra disponible
            </p>
          </div>
        </mat-card-content>
        <div #canvasPiePDF id="canvasPiePDF" class="pie-graphic-PDF"></div>
      </div>
    </mat-card>

    <mat-card
      style="background: #f3f3f3;"
      class="card-container chart-container-pdf mb-1"
      data-chartName="distribucionGenero"
    >
      <div class="chart-content">
        <mat-card-title
          style="
            font-size: 20px;
            font-family: 'Nunito', sans-serif;
            font-weight: bold;
          "
        >
          {{ chartTabs.consultasPorGenero.title }}
          <mat-icon
            style="position: absolute; margin-left: 5px; color: #244791;"
            matTooltip="Principales motivos de consulta realizados por los usuarios de su organización en el período de tiempo seleccionado."
            matTooltipClass="custom-tooltips"
            matTooltipPosition="after"
            >info_outline</mat-icon
          ></mat-card-title
        >
        <mat-divider></mat-divider>
        <mat-card-content>
          <mat-spinner
            *ngIf="isLoading"
            style="margin-top: 20%; margin-left: 45%;"
            diameter="60"
          ></mat-spinner>
          <div *ngIf="!isLoading && gendersLength === 0">
            <p class="no-data-graphic-pdf simple">
              El gráfico aún no se encuentra disponible
            </p>
          </div>
        </mat-card-content>
        <div
          #canvasPieGenderPDF
          id="canvasPieGenderPDF"
          class="pie-graphic-PDF"
        ></div>
      </div>
    </mat-card>

    <mat-card
      style="background: #f3f3f3;"
      class="card-container chart-container-pdf mb-1"
      data-chartName="distribucionEdad"
    >
      <div class="chart-content">
        <mat-card-title
          style="
            font-size: 20px;
            font-family: 'Nunito', sans-serif;
            font-weight: bold;
          "
        >
          {{ chartTabs.consultasPorEdad.title }}
          <mat-icon
            style="position: absolute; margin-left: 5px; color: #244791;"
            matTooltip="Principales motivos de consulta realizados por los usuarios de su organización en el período de tiempo seleccionado."
            matTooltipClass="custom-tooltips"
            matTooltipPosition="after"
            >info_outline
          </mat-icon>
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <mat-spinner
            *ngIf="isLoading"
            style="margin-top: 20%; margin-left: 45%;"
            diameter="60"
          ></mat-spinner>
          <div *ngIf="!isLoading && ageConsultationLength === 0">
            <p class="no-data-graphic-pdf simple">
              El gráfico aún no se encuentra disponible
            </p>
          </div>
          <div
            #canvasBarPerAgePDF
            id="canvasBarPerAgePDF"
            class="bar-graphic-PDF"
            style="transform: translate(0px, 40px);"
          ></div>
        </mat-card-content>
      </div>
    </mat-card>

    <div class="row justify-content-center">
      <div class="" style="margin-top: 5%; width: 12cm;">
        <div
          class="first-block chart-container-pdf"
          style="background: #ffffff;"
          data-chartName="firstBlock"
        >
          <div style="display: flex; gap: 34px;">
            <!-- TASA DE USO -->
            <mat-card
              class="card-min-container col-5"
              data-chartName="tasaUso"
              style="background: #f3f3f3;"
            >
              <div class="row">
                <div class="col-12">
                  <mat-card-title class="card-min-title">
                    Tasa de uso
                    <mat-icon
                      style="
                        position: absolute;
                        margin-left: 5px;
                        color: #244791;
                      "
                      matTooltip="Porcentaje de uso del servicio de telemedicina por parte de los miembros de su organización. Este indicador excluye consultas de seguimiento y consultas posteriores por nuevas patologías."
                      matTooltipClass="custom-tooltips"
                      matTooltipPosition="after"
                      >info_outline
                    </mat-icon>
                  </mat-card-title>

                  <mat-card-title *ngIf="!isLoading" class="card-min-subtitle">
                    <div class="content-card">
                      <span class="value">
                        {{
                          dashboardData?.rateConsultation > 0
                            ? isInteger(dashboardData.rateConsultation) + "%"
                            : "0%"
                        }}
                      </span>

                      <span
                        *ngIf="dashboardData?.trendConsultation == 0 && !isData"
                        style="
                          font-size: 14px;
                          font-family: 'open sans', sans-serif;
                          top: 57px;
                          margin-left: 10px;
                          color: #23bc60;
                        "
                      >
                        <img
                          style="width: 10px;"
                          src="../../assets/images/flecha-verde.png"
                          alt=""
                        />
                        <span>
                          {{ isInteger(dashboardData.trendConsultation) }}%
                        </span>
                      </span>
                    </div>
                    <span
                      *ngIf="dashboardData?.trendConsultation < 0 && isData"
                      style="
                        font-size: 14px;
                        top: 50px;
                        margin-left: 10px;
                        color: #d22f37;
                      "
                    >
                      <img
                        style="width: 10px;"
                        src="../../assets/images/flecha-roja.png"
                        alt=""
                      />
                      {{ isInteger(dashboardData.trendConsultation) }}%
                    </span>
                    <span
                      *ngIf="dashboardData?.trendConsultation == 0 && isData"
                      style="
                        font-size: 14px;
                        top: 50px;
                        margin-left: 10px;
                        color: rgba(0, 0, 0, 0.4);
                      "
                    >
                      -- sin var.
                    </span>
                  </mat-card-title>
                  <mat-card-title
                    *ngIf="isLoading"
                    class="card-min-process-bar"
                  >
                    <mat-progress-bar color="warn" mode="indeterminate">
                    </mat-progress-bar
                  ></mat-card-title>
                </div>
                <div class="col-2" style="display: none;">
                  <button
                    disabled
                    style="
                      color: #244791;
                      background-color: #d8e4fc;
                      width: 64px;
                      height: 64px;
                    "
                    mat-fab
                  >
                    <img
                      style="width: 95%; margin-top: -10%;"
                      src="../../assets/images/coolicon.svg"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </mat-card>

            <!-- VALORACION MEDIA -->
            <mat-card
              class="card-min-container col-6"
              style="margin-bottom: 20px;"
              data-chartName="valoracionMedia"
              style="background: #f3f3f3;"
            >
              <div class="row">
                <div class="col-12">
                  <mat-card-title class="card-min-title"
                    >Valoración media
                    <mat-icon
                      style="
                        position: absolute;
                        margin-left: 5px;
                        color: #244791;
                      "
                      matTooltip="Valoración promedio del servicio de telemedicina HolaDOC, por parte de los miembros de su organización considerando el período de tiempo seleccionado."
                      matTooltipClass="custom-tooltips"
                      matTooltipPosition="after"
                    >
                      info_outline
                    </mat-icon>
                  </mat-card-title>
                  <mat-card-title
                    *ngIf="!isLoading && dashboardData?.rateRating > 0"
                    class="card-min-subtitle content-card"
                  >
                    <span class="value">
                      {{ isInteger(dashboardData.rateRating) + "/5" }}
                    </span>
                    <span
                      *ngIf="dashboardData.trendRating > 0 && isData"
                      style="
                        font-size: 14px;
                        top: 50px;
                        margin-left: 10px;
                        color: #23bc60;
                      "
                    >
                      <img
                        style="width: 10px;"
                        src="../../assets/images/flecha-verde.png"
                        alt=""
                      />
                      {{ isInteger(dashboardData.trendRating) }}%
                    </span>
                    <span
                      *ngIf="isData && dashboardData.trendRating < 0"
                      style="
                        font-size: 14px;
                        top: 50px;
                        margin-left: 10px;
                        color: #d22f37;
                      "
                    >
                      <img
                        style="width: 10px;"
                        src="../../assets/images/flecha-roja.png"
                        alt=""
                      />
                      {{ isInteger(dashboardData.trendRating) }}%
                    </span>
                    <span
                      *ngIf="isData && dashboardData.trendRating == 0"
                      style="
                        font-size: 14px;
                        position: absolute;
                        top: 50px;
                        margin-left: 10px;
                        color: rgba(0, 0, 0, 0.4);
                      "
                    >
                      -- sin var.
                    </span>
                  </mat-card-title>

                  <mat-card-title
                    *ngIf="!isLoading && !dashboardData?.rateRating"
                    class="card-min-subtitle"
                  >
                    <span
                      style="
                        font-size: 14px;
                        position: absolute;
                        top: 50px;
                        color: rgba(0, 0, 0, 0.4);
                        font-family: Nunito;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                      "
                    >
                      Sin datos disponibles
                    </span>
                  </mat-card-title>
                  <mat-card-title
                    *ngIf="isLoading"
                    class="card-min-process-bar"
                  >
                    <mat-progress-bar color="warn" mode="indeterminate">
                    </mat-progress-bar
                  ></mat-card-title>
                </div>
                <div class="col-2" style="display: none;">
                  <button
                    disabled
                    mat-fab
                    style="
                      color: #244791;
                      background-color: #d8e4fc;
                      width: 64px;
                      height: 64px;
                    "
                  >
                    <mat-icon style="transform: scale(1.24); font-weight: bold;"
                      >grade</mat-icon
                    >
                  </button>
                </div>
              </div>
            </mat-card>
          </div>

          <!-- USUARIOS CON EL USO DEL SERVICIO -->
          <mat-card
            class="card-min-container chart-container-pdf"
            data-chartName="usuariosUsoServicio"
            style="background: #f3f3f3;"
          >
            <div class="row">
              <div class="col-9">
                <mat-card-title class="card-min-title">
                  Usuarios con el uso del servicio
                  <mat-icon
                    style="
                      position: absolute;
                      margin-left: 5px;
                      color: #244791;
                    "
                    matTooltip="Indica la cantidad de miembros de su organización que han usado el servicio al menos 1 vez."
                    matTooltipClass="custom-tooltips"
                    matTooltipPosition="after"
                    >info_outline</mat-icon
                  ></mat-card-title
                >
                <mat-card-title
                  *ngIf="!isLoading"
                  class="card-min-subtitle content-card"
                >
                  <span class="value">
                    {{
                      dashboardData?.usersWithServiceUsage > 0
                        ? thousandSeparator(dashboardData.usersWithServiceUsage)
                        : "0"
                    }}
                  </span>
                </mat-card-title>
                <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
                  <mat-progress-bar color="warn" mode="indeterminate">
                  </mat-progress-bar
                ></mat-card-title>
              </div>
              <div class="col-2" style="display: none;">
                <button
                  disabled
                  mat-fab
                  style="
                    color: #244791;
                    background-color: #d8e4fc;
                    width: 64px;
                    height: 64px;
                  "
                >
                  <mat-icon style="transform: scale(1.2); font-weight: bold;"
                    >data_usage</mat-icon
                  >
                </button>
              </div>
            </div>
          </mat-card>
        </div>

        <div
          class="second-block chart-container-pdf"
          style="background: #ffffff;"
          data-chartName="secondBlock"
        >
          <!-- TASA DE REGISTRO ACUMULADA -->
          <mat-card
            class="card-min-container"
            data-chartName="tasaRegistroAcumulada"
            style="background: #f3f3f3;"
          >
            <div class="row">
              <div class="col-9">
                <mat-card-title class="card-min-title"
                  >Tasa de registro acumulada
                  <mat-icon
                    style="
                      position: absolute;
                      margin-left: 5px;
                      color: #244791;
                    "
                    matTooltip="Porcentaje usuarios registrados en HolaDOC, en relación a los miembros de su organización."
                    matTooltipClass="custom-tooltips"
                    matTooltipPosition="after"
                    >info_outline</mat-icon
                  ></mat-card-title
                >
                <mat-card-title
                  *ngIf="!isLoading"
                  class="card-min-subtitle content-card"
                >
                  <span class="value">
                    {{
                      members?.accumulatedRegistered > 0
                        ? isInteger(members.accumulatedRegistered) + "%"
                        : "0%"
                    }}
                  </span>
                </mat-card-title>
                <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
                  <mat-progress-bar color="warn" mode="indeterminate">
                  </mat-progress-bar
                ></mat-card-title>
              </div>
              <div class="col-2" style="display: none;">
                <button
                  disabled
                  mat-fab
                  style="
                    color: #244791;
                    background-color: #d8e4fc;
                    width: 64px;
                    height: 64px;
                  "
                >
                  <mat-icon style="transform: scale(1.2); font-weight: bold;">
                    data_usage</mat-icon
                  >
                </button>
              </div>
            </div>
          </mat-card>

          <!-- TASA DE RECURRENCIA -->
          <mat-card
            class="card-min-container"
            data-chartName="tasaRecurrencia"
            style="background: #f3f3f3;"
          >
            <div class="row">
              <div class="col-9">
                <mat-card-title class="card-min-title" card-min-responsive
                  >Tasa de recurrencia
                  <mat-icon
                    style="
                      position: absolute;
                      margin-left: 5px;
                      color: #244791;
                    "
                    matTooltip="Porcentaje de miembros que han usado el servicio de telemedicina más de 2 veces."
                    matTooltipClass="custom-tooltips"
                    matTooltipPosition="after"
                  >
                    info_outline
                  </mat-icon>
                </mat-card-title>
                <mat-card-title
                  *ngIf="!isLoading"
                  class="card-min-subtitle content-card"
                >
                  <div class="value">
                    {{
                      dashboardData?.recurrenceRate > 0
                        ? isInteger(dashboardData.recurrenceRate) + "%"
                        : "0%"
                    }}
                  </div>
                </mat-card-title>
                <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
                  <mat-progress-bar color="warn" mode="indeterminate">
                  </mat-progress-bar
                ></mat-card-title>
              </div>
              <div class="col-2" style="display: none;">
                <button
                  disabled
                  mat-fab
                  style="
                    color: #244791;
                    background-color: #d8e4fc;
                    width: 64px;
                    height: 64px;
                  "
                >
                  <mat-icon style="transform: scale(1.2); font-weight: bold;">
                    data_usage
                  </mat-icon>
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
